$color-warning: rgb(250, 164, 4);
$btn-primary: #1f61dc;
$btn-secondary: pink;
$btn-background: rgb(160, 160, 160);

.fi_msg {
  .rs-message-container {
    padding: 0.5rem 0.15rem;
  }
  margin: 0.5rem;
  //   margin-left: 0;
}

.sign-area {
  margin: 1rem 0;
  .sign-header {
    height: 20px;
    background-color: lighten($color-warning, 25);
    color: #2e3849;
    font-family: monospace;
    text-transform: uppercase;
    padding-left: 4px;
  }
  .sign-body {
    width: 100%;
    // min-height: 200px;
    background-color: lighten($btn-background, 25);
    border-right: 1px dashed lighten($btn-background, 15);
    border-left: 1px dashed lighten($btn-background, 15);
    border-bottom: 1px dashed lighten($btn-background, 15);
  }
  .sign-footer {
  }
}
